<template>
  <span>{{(show || !showIcon) ? text : '****'}}<span v-if="showIcon" class="show-a" @click="_setShow">{{show ? '隐藏' : '显示'}}</span></span>
</template>

<script>
export default {
  props: ['text'],
  data() {
    return {
      showIcon: false,
      show: false,
    }
  },
  created() {
    if(isGuest()){
      this.showIcon = true;
    }
  },

  methods: {
    _setShow(){
      this.show = !this.show;
    }
  },
}
</script>
<style lang="scss" scoped>
  .show-a{
    color: blue;
    font-size: 12px;
    margin-left: 10px;
    cursor: pointer;
  }
</style>
